import React from 'react';

const About = () => {
  return (
    <section id="about" className='p-5 max-w-screen-xl mx-auto'>
      <h2 class="slider slide-in text-3xl font-semibold p-5 tracking-wider">About Me</h2>
      <p class="slider slide-in leading-loose">I first started programming in high school, at first it was just Gcode and simple C programs for engineering classes. 
                                              By my senior year, I was programming in C++ as part of a local First Robotics team and I was hooked.
                                              I love making things, and I love computers. Programming is just the perfect fusion of two of my passions.
                                              Besides programming, I also enjoy 3D modeling, 3D printing, and I also play around with self hosting game servers for friends!
                                                </p>
      <p class="slider slide-in text-lg leading-loose font-semibold">See the content below for more!</p>
    </section>
  );
};

export default About;
