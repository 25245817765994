import React from 'react';

import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer'

const App = () => {

  return (
    <div className='bg-gray-50 dark:bg-gray-900 text-gray-700 dark:text-gray-300 flex items-center flex-col min-h-screen'>
      <Navbar />
      <div class='p-5 flex-1 flex-col text-center'>
        <Home />
        <About />
        <Projects />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default App;
