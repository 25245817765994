import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
//import { faPhone } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
  return (
    <section id='contact' className='flex flex-col items-center p-5'>
      <h2 className='text-2xl font-semibold p-5'>Contact Me</h2>
      <div className='flex sm:flex-row flex-col p-5 space-x-5 text-lg font-semibold'>
        <a href='mailto:bradyt.mccoy@gmail.com' className='hover:text-violet-600'>
          <p><FontAwesomeIcon icon={faEnvelope}/> Email: bradyt.mccoy@gmail.com</p>
        </a>
        <a href='https://github.com/BradenMccoy' target='_blank' rel="noopener noreferrer" className='hover:text-violet-600'>
          <p><FontAwesomeIcon icon={faGithub}/> Github</p>
        </a>
        <a href='https://www.linkedin.com/in/bradenmccoy' target='_blank' rel="noopener noreferrer" className='hover:text-violet-600'>
          <p><FontAwesomeIcon icon={faLinkedin}/> LinkedIn</p>
        </a>
      </div>
    </section>
  );
};

export default Contact;
