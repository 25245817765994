import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNavicon } from '@fortawesome/free-solid-svg-icons';

const scroll = (id) => {
  const section = document.querySelector(id);
  section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className='flex sm:flex-row flex-col justify-between bg-gray-300 dark:bg-gray-950 p-5 w-full'>

      <div className='flex items-center justify-between mr-6'>
        <button onClick={()=>{setIsOpen(!isOpen)}}><FontAwesomeIcon className='sm:hidden' icon={faNavicon}/></button>
        <span className='text-right px-3 font-semibold text-2xl tracking-widest'>Braden McCoy</span>
      </div>

      {(isOpen || (window.innerWidth > 640)) && (
        <ul className='flex list-none flex-col sm:flex-row'>
          <li className='sm:px-3 py-2 rounded-lg hover:text-violet-600 text-xl'><button className='tracking-widest' onClick={() => scroll('#home')}>Home</button></li>
          <li className='sm:px-3 py-2 rounded-lg hover:text-violet-600 text-xl'><button className='tracking-widest' onClick={() => scroll('#about')}>About</button></li>
          <li className='sm:px-3 py-2 rounded-lg hover:text-violet-600 text-xl'><button className='tracking-widest' onClick={() => scroll('#projects')}>Projects</button></li>
          <li className='sm:px-3 py-2 rounded-lg hover:text-violet-600 text-xl'><button className='tracking-widest' onClick={() => scroll('#contact')}>Contact</button></li>
        </ul>
      )}
      
    </nav>
  );
};

export default Navbar;
