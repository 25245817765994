import React from 'react';
import skelligPhoto from '../img/skellig_photo.webp';

const Home = () => {
  return (
    <section id='home' className='flex items-center flex-col p-5'>
      <h1 className="slider slide-in text-4xl font-semibold tracking-wider my-8">Welcome to My Portfolio</h1>
      <div className='flex p-5 items-center max-w-4xl flex-col'>
        <figure className='mr-5 mb-12 cursor-pointer filter grayscale-0'>
          <img className='h-auto sm:max-w-md rounded-lg shadow-xl shadow-gray-400 dark:shadow-gray-950 transition-all duration-300 hover:blur-sm' src={skelligPhoto} alt='Me at the summit of Skellig Michael, an island off the coast of Ireland'></img>
          <figcaption className='absolute px-4 sm:text-lg text-md text-stone-700 font-semibold sm:top-2 top-0'>
            <p>This is Skellig Michael, an island 8km off the coast of Ireland and once believed to be the edge of the world.</p>
          </figcaption>
        </figure>
        <article className='inline-block'>
          <p class="slider slide-in leading-loose">Hi, I'm Braden (Brady) McCoy. I'm a software developer with a B.S. in Computer Science from Western Washington University.</p>
        </article>
      </div>
    </section>
  );
};

export default Home;
