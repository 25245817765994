import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import tw from "twin.macro";
import styled from "styled-components";

import nlFurniture from '../img/nlFurniture.webp';
import giraffeStore from '../img/giraffeStore.webp';

const Container = tw.div`relative`;
const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingTitle = tw.h2`text-xl sm:text-5xl font-black tracking-wide text-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);

const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide`;
const Title = tw.h4`text-3xl font-bold`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-gray-500`;
const LinkDescription = tw.a`mr-2`;


const Projects = () => {

  const cards = [
    {
      imageSrc:
        nlFurniture,
      subtitle: "",
      title: "New Life Furniture Restoration",
      description:
        "A custom built website for a local business, features a main page as well as a commission form. Responsive design that works for a wide range of screensizes. Built with React, Tailwind and the Treact component library.",
      urlTitle: "Site Link",
      url: "https://nlfurniturerestoration.com/"
    },
    {
      imageSrc:
        giraffeStore,
      subtitle: "",
      title: "Giraffe Store",
      description:
        "A Giraffe themed mock storefront. This site is completely vanilla JS on the frontend, with an Express.js API and MySQL database.",
      urlTitle: "Repo Link",
      url: "https://github.com/BradenMccoy/giraffe-store"
    }
  ];


  return (
    <Container id='projects'>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>My Projects</HeadingTitle>
          <HeadingDescription>
            
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link target='_blank' rel="noopener noreferrer" href={card.url}><LinkDescription>{card.urlTitle}</LinkDescription><FontAwesomeIcon icon={faExternalLink}/></Link>
                
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
    </Container>
  );
};

export default Projects;
